<template>
  <b-row class="container p-0 m-0 " style="background-color:white">
    <!-- Image column -->
    <b-col
      cols="0"
      md="4"
      class="image-col"
      :style="`background: url('${formImage}')`"
    />

    <!-- Form column -->
    <b-col cols="12" md="8" class="px-1 pb-1 px-md-5 h-100">
      <div class="h-100 d-flex flex-column align-items-center justify-content-center">
        
        <!-- Is loading -->
        <div v-if="isLoading" class="w-100 px-md-4 pt-3 pt-md-5 px-1 pb-1 h-100">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="primary" label="Loading..." />
          </div>
        </div>
        
        <!-- Form container -->
        <div v-else class="w-100 px-md-4 pt-3 pt-md-5 px-1 pb-1 h-100">
          <!-- Scrollable form container -->
          <app-scroll 
            v-if="form" 
            :options="{ scrollPanel: { scrollingX: false } }"
            class="h-100"
            >
            <!-- Title and space name -->
            <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
              <h2 class="mb-1 form-medium-3">
                {{ translate(form.name) }}
              </h2>
              <b-badge v-if="!isOnboarding" pill variant="primary" class="my-25">
                {{ $t('form.type.' + this.form.type) }}
              </b-badge>
              <h6 v-if="isOnboarding && currentSpace">
                {{ translate(currentSpace.name) }}
              </h6>
            </div>

            <!-- Form description and questions -->
            <b-form
              v-if="!isFormAlreadyAnswered"
              :key="keyToRerender"
              @submit.prevent="submitForm"
            >
              <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
                <p v-if="translate(form.description)" v-html="translate(form.description)" />
              </div>

              <div 
                v-for="question in form.questions" 
                v-show="form.questions && form.questions.length>0" 
                :key="question.key"
              >

                <!-- Type: Text, Email, Phone, Number -->
                <b-form-group
                  v-if="
                    question.type === 'text' ||
                      question.type === 'email' ||
                      question.type === 'phone' ||
                      question.type === 'number'
                  "
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <!-- Type text -->
                  <b-form-input
                    :id="question.key"
                    v-model="registerForm[translate(question.key)]"
                    :type="type[question.type]"
                    :required="question.rules && question.rules.indexOf('required') > -1"
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    :aria-describedby="question.key"
                  />
                </b-form-group>

                <!-- Type: Date -->
                <b-form-group
                  v-else-if="question.type === 'date'"
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <b-input-group>
                    <b-form-input
                      :id="question.key"
                      v-model="registerForm[translate(question.key)]"
                      class="h-auto"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        :id="question.key"
                        v-model="registerForm[translate(question.key)]"
                        :type="type[question.type]"
                        button-only
                        right
                        size="sm"
                        button-variant="primary"
                        today-button
                        reset-button
                        close-button
                        :locale="locale"
                        :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                        :required="question.rules && question.rules.indexOf('required') > -1"
                        :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                        aria-controls="example-input"
                        @context="onContext"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <!-- Type: radio group -->
                <b-form-group
                  v-else-if="
                    question.type === 'radio' && question.options && Object.values(question.options).length > 0
                  "
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <b-form-radio-group
                    :id="question.key"
                    v-model="registerForm[translate(question.key)]"
                    :type="type[question.type]"
                    :required="question.rules && question.rules.indexOf('required') > -1"
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    :options="selectOptions(question.options)"
                  />
                </b-form-group>

                <!-- Type: Radio with options -->
                <b-form-checkbox
                  v-else-if="question.type === 'radio' && Object.keys(question.options).length === 0"
                  :id="question.key"
                  v-model="registerForm[translate(question.key)]"
                  switch
                  :unchecked-value="false"
                  :required="question.rules && question.rules !=='' && question.rules.indexOf('required') > -1"
                  class="mb-2 mt-2"
                >
                  <p v-if="question.rules && question.rules.indexOf('required') > -1">
                    {{ translate(question.name) }} *
                  </p>
                  <p v-else>
                    {{ translate(question.name) }}
                  </p>
                </b-form-checkbox>

                <!-- Type: Checkbox -->
                <b-form-checkbox
                  v-else-if="question.type === 'checkbox'"
                  :id="question.key"
                  v-model="registerForm[translate(question.key)]"
                  :required="question.rules && question.rules.indexOf('required') > -1?true:false"
                  class="mb-2 mt-2"
                >
                  <p v-if="question.rules && question.rules.indexOf('required') > -1">
                    {{ translate(question.name) }} *
                  </p>
                  <p v-else>
                    {{ translate(question.name) }}
                  </p>
                </b-form-checkbox>

                <!-- Type: Toggle checkbox -->
                <b-form-checkbox
                  v-else-if="question.type === 'toggle' || question.type === 'boolean'"
                  :id="question.key"
                  v-model="registerForm[translate(question.key)]"
                  switch
                  :unchecked-value="false"
                  :required="question.rules && question.rules.indexOf('required') > -1"
                  class="mb-2 mt-2"
                >
                  <p v-if="question.rules && question.rules.indexOf('required') > -1">
                    {{ translate(question.name) }} *
                  </p>
                  <p v-else>
                    {{ translate(question.name) }}
                  </p>
                </b-form-checkbox>

                <!-- Type: TextArea -->
                <b-form-group
                  v-else-if="question.type === 'textarea'"
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <b-form-textarea
                    :id="question.key"
                    v-model="registerForm[translate(question.key)]"
                    :type="type[question.type]"
                    :required="question.rules && question.rules.indexOf('required') > -1"
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    rows="5"
                  />
                </b-form-group>

                <!-- Type: Upload file -->
                <b-form-group 
                  v-else-if="question.type === 'upload-file'" 
                  :label="
                    question.rules && question.rules.includes('required')
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <file-upload
                    :id="question.key"
                    v-model="registerForm[translate(question.key)]"
                    type="file"
                    cancelable
                    :placeholder="$t('form-create-item.file-button')"
                    :drop-placeholder="$t('form-create-item.drop-placeholder')"
                    @cancel="registerForm[translate(question.key)] = null"
                  />
                </b-form-group>

                <!-- Type: Selector -->
                <b-form-group
                  v-else-if="question.type === 'select' "
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <v-select
                    id="type"
                    v-model="registerForm[translate(question.key)]"
                    class="select-control p-0"
                    :class="question.maxAnswers>1? 'select-multiple':''"
                    :clearable="false"
                    searchable
                    :multiple="question.maxAnswers>1"
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    :options=" selectOptions(question.options)"
                    @input="registerForm[translate(question.name)]"
                  >
                    <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                      <input
                        class="vs__search"
                        :required="!registerForm[translate(question.key)]"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>

                <!-- Type: Country selector -->
                <b-form-group
                  v-else-if="question.type === 'country'"
                  :label="
                    question.rules && question.rules.includes('required')
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <v-select
                    id="type"
                    v-model="registerForm[translate(question.key)]"
                    class="select-control p-0"
                    :class="question.maxAnswers>1? 'select-multiple':''"
                    :clearable="false"
                    searchable
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    :options="countries"
                    :reduce="country => country.name"
                    label="name"
                    :get-option-label="country => country.name"
                    :multiple="question.maxAnswers>1"
                    @input="registerForm[translate(question.name)]"
                  >
                    <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                      <input
                        class="vs__search"
                        :required="!registerForm[translate(question.key)]"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>

                <!-- Type: Organization -->
                <b-form-group
                  v-else-if="question.type === 'organization'"
                  :label="
                    question.rules && question.rules.includes('required')
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <template>
                    <members-select
                      v-if="question.showMyOrganizations"
                      ref="organizations"
                      :key="`${membersKey}-my`"
                      v-model="registerForm[translate(question.key)]"
                      class="select-control p-0"
                      :class="question.maxAnswers>1? 'select-multiple':''"
                      :searchable="!isLoadingQuestions"
                      :multiple="question.maxAnswers>1"
                      :clearable="false"
                      :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                      dir="ltr"
                      input-id="profile-organizations"
                      :fetch-data="fetchMyOrganizations"
                      :has-code="true"
                      :code="organizationsFilter"
                      label="name"
                    >
                      <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!registerForm[translate(question.key)]"
                          v-bind="attributes"
                          v-on="events"
                        >
                      </template>
                    </members-select>
                    <members-select
                      v-else
                      ref="organizations"
                      :key="`${membersKey}-all`"
                      v-model="registerForm[translate(question.key)]"
                      class="select-control p-0"
                      :class="question.maxAnswers>1 && haveOrganizations? 'select-multiple':''"
                      :searchable="!isLoadingQuestions"
                      :multiple="question.maxAnswers>1 && haveOrganizations"
                      :clearable="false"
                      :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                      dir="ltr"
                      input-id="profile-organizations"
                      :fetch-data="fetchOrganizations"
                      :has-code="true"
                      :code="organizationsFilter"
                      label="name"
                    >
                      <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!registerForm[translate(question.key)]"
                          v-bind="attributes"
                          v-on="events"
                        >
                      </template>
                    </members-select>
                  </template>
                  <div v-if="question.canCreateOrganization" class="d-flex justify-content-end">
                    <p class="small mb-0 mt-25 text-primary">
                      {{ $t('onboarding.form.find-organization') }} <span class="text-primary pointer" style="text-decoration: underline" @click="isAddOrganizationModalVisible = true">{{ $t('onboarding.form.create') }}</span>
                    </p>
                  </div>
                </b-form-group>

                <!-- Type: Society -->
                <b-form-group
                  v-else-if="question.type === 'society'"
                  :label="
                    question.rules && question.rules.includes('required')
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <template>
                    <members-select
                      ref="organizations"
                      :key="membersKey"
                      v-model="registerForm[translate(question.key)]"
                      class="select-control p-0"
                      :class="question.maxAnswers>1&& haveSocieties? 'select-multiple':''"
                      :searchable="!isLoadingQuestions"
                      :multiple="question.maxAnswers>1 && haveSocieties"
                      :clearable="false"
                      :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                      dir="ltr"
                      input-id="profile-organizations"
                      :fetch-data="fetchSocieties"
                      :has-code="true"
                      :code="societiesFilter"
                      label="name"
                    >
                      <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!registerForm[translate(question.key)]"
                          v-bind="attributes"
                          v-on="events"
                        >
                      </template>
                    </members-select>
                  </template>
                </b-form-group>

                <!-- Type: Classifier selector -->
                <b-form-group
                  v-else-if="question.type === 'classifier'"
                  :label="
                    question.rules && question.rules.includes('required')
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <v-select
                    id="type"
                    v-model="registerForm[translate(question.key)]"
                    class="select-control p-0"
                    :class="question.maxAnswers>1? 'select-multiple':''"
                    :clearable="false"
                    searchable
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                    :options="classifiersOptions[question.key]"
                    :reduce="classifier => classifier.code"
                    label="text"
                    :multiple="question.classifierTypeLink && question.classifierTypeLink.multiple"
                    @input="registerForm[translate(question.name)]"
                  >
                    <template v-if="question.rules && question.rules.includes('required')" #search="{attributes, events}">
                      <input
                        class="vs__search"
                        :required="!registerForm[translate(question.key)]"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                </b-form-group>

                <!-- Type: Infotext -->
                <b-form-group 
                  v-else-if="question.type === 'infotext'" 
                  :id="question.key"
                >
                  <p>
                    {{ translate(question.name) }}
                  </p>
                </b-form-group>

                <!-- Type: Text -->
                <b-form-group
                  v-else
                  :label="
                    question.rules && question.rules.indexOf('required') > -1
                      ? `${translate(question.name)} *`
                      : translate(question.name)
                  "
                  :label-for="question.key"
                >
                  <b-form-input
                    :id="question.key"
                    v-model="registerForm[translate(question.key)]"
                    type="text"
                    :required="question.rules && question.rules.indexOf('required') > -1"
                    :placeholder="question.placeholder ? translate(question.placeholder) : translate(question.name)"
                  />
                </b-form-group>
              </div>

              <b-alert :show="!form.questions.length > 0" variant="warning" class="text-center mb-3 mt-5">
                {{ $t('backoffice.communication.forms.warning-no-questions') }}
              </b-alert>
              <!-- Error Toast -->
              <div class="error-toast mb-1">
                <b-alert
                  v-if="generalError"
                  show
                  variant="danger"
                  class="m-0 error-notification"
                >
                  {{ $t('error-message.general-error') }}
                </b-alert>
              </div>

              <!-- submit buttons -->
              <div v-if="isSending" class="mt-2 mb-3 w-100 d-flex justify-content-center">
                <b-spinner variant="primary" label="Loading..." />
              </div>
              <div v-else>
                <b-button
                  type="submit"
                  class="mt-2"
                  variant="primary"
                  block
                  :disabled="disabled"
                >
                  {{ $t('backoffice.communication.forms.send-answers') }}
                </b-button>
                <p v-if="preview" class="mt-1 mb-50 text-primary">
                  <feather-icon size="14" class="mr-50" icon="AlertTriangleIcon" /> {{ $t('backoffice.communication.forms.note-preview') }}
                </p>
              </div>
            </b-form>

            <!-- When form is already answered -->
            <div v-else class="d-flex flex-column align-items-center justify-content-center">
              {{ $t('form.already-verified') }}
              <b-button
                variant="primary"
                class="mt-1"
                style="width: 100px;"
                block
                @click="redirectHome()"
              >
              {{ $t('form.go-inside') }}
              </b-button>
            </div>
          </app-scroll>

          <!-- When form is not found -->
          <div v-else class="d-flex flex-column align-items-center justify-content-center">
            <!-- space name -->
            <h6 v-if="currentSpace">
              {{ translate(currentSpace.name) }}
            </h6>
            {{ $t(isOnboarding ? 'form.without-onboarding-form' : 'form.doesnt-exist') }}
            <b-button
              variant="primary"
              class="mt-1"
              style="width: 100px;"
              block
              @click="redirectHome()"
            >
            {{ $t('form.go-inside') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-col>

    <!-- Add Organization Modal -->
    <b-modal
      v-model="isAddOrganizationModalVisible"
      centered
      hide-footer
      size="lg"
    >
      <template #modal-header>
        <language-selector-header :title="$t('organizations.modal-create-title')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <membership-event-create-modal
        item-type="organizations"
        :selected="selectedLanguage"
        :can-create-organization="true"
        :from-onboarding="true"
        @close-modal="closeModal"
      />
    </b-modal>
  </b-row>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import MembershipEventCreateModal from '@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required } from '@validations';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import { randomString } from '@/@core/utils/utils';
import authImage from '@/assets/images/pages/login/auth_image.svg';
import AppScroll from '@core/components/app-scroll/AppScroll.vue';
import { getImageResource } from '@/@core/utils/image-utils';
import OnboardinformImageCa from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_ca.svg';
import OnboardinformImageEs from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_es.svg';
import OnboardinformImageEn from '@/assets/images/pages/onboarding-form/onboardingFormDefaultPhoto_en.svg';
import formDefaultImage from '@/assets/images/pages/FormsDefaultPhoto.svg';
import FileUpload from '@core/components/files/FileUpload.vue';

export default {
  name: 'FormPreview',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    MembersSelect,
    MembershipEventCreateModal,
    LanguageSelectorHeader,
    AppScroll,
    FileUpload,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      isSending: false,
      isLoadingQuestions: false,
      registerForm: {},
      generalError: false,
      newOrganization: '',
      formatted: '',
      selected: '',
      required,
      haveSocieties: true,
      haveOrganizations: true,
      selectedLanguage: this.currentLocale,
      isAddOrganizationModalVisible: false,
      type: {
        text: 'text',
        phone: 'tel',
        number: 'number',
        email: 'email',
        date: 'date',
        'select-endpoint': 'select',
        checkbox: 'checkbox',
        select: 'select',
        countries: 'countries',
        infotext: 'infotext',
        textarea: 'textarea',
        radio: 'radio',
        file: 'file',
      },
      membersKey: 'a',
      keyToRerender: 'Key',
      classifiersOptions: {},
      lastLoadedPageOrganizations: 1,
      communityMember: {},
      currentSpace: {},
      file: null
    };
  },
  computed: {
    communitySlug() {
      return this.$route.params.communityId;
    },
    isOnboarding() {
      return this.$route.name === 'onboarding-form';
    },
    preview() {
      return this.$route.query.preview;
    },
    formKey() {
      if (this.isOnboarding) {
        return this.form.key;
      }
      return this.$route.params.id;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    currentImage() {
      return this.$store.getters.authImage?.community_customizations?.auth_image_url != null ? this.$store.getters.authImage?.community_customizations?.auth_image_url['/login'].es : null;
    },
    previewImage() {
      return authImage;
    },
    requiredQuestions() {
      const requiredQuestions = [];
      this.form.questions.forEach(
        (question) => (question.rules && question.rules.indexOf('required') > -1
          ? requiredQuestions.push(question.key) : ''),
      );
      return requiredQuestions;
    },
    disabled() {
      const isActive = this.requiredQuestions.every(
        (question) => this.registerForm[question] && this.registerForm[question] != null,
      );
      return !isActive || this.form.questions.length === 0;
    },
    form() {
      if (this.$store.getters.forms) {
        return this.$store.getters.forms.unpaginated[0];
      }
      return [];
    },
    isFormAlreadyAnswered() {
      return this.isOnboarding && !this.preview && this.communityMember?.status === 3;
    },
    countries() {
      if (this.$store.getters.countries) {
        const countries = this.$store.getters.countries.unpaginated;
        const arrayCountries = [];
        countries.forEach((country) => arrayCountries.push({ name: this.translate(country.name), code: country.code }));
        return arrayCountries;
      }
      return [];
    },
    organizations() {
      if (this.$store.getters.communitiesOthers) {
        return this.$store.getters.communitiesOthers.organizations.unpaginated;
      }
      return [];
    },
    locales() {
      return this.$store.getters.locales;
    },
    availableLanguages() {
      return this.$store.getters.currentCollective.availableLanguages;
    },
    locale() {
      const locale = this.currentLocale;
      if (locale === 'en') {
        return 'en-US';
      }
      if (locale === 'es') {
        return 'es-ES';
      }
      return 'ca-CA';
    },
    currentLocale() {
      if (this.$route.query.code) {
        return this.$route.query.code;
      }
      return this.$store.state.locale.currentLocale;
    },
    code() {
      return this.$route.query.code;
    },
    classifiers() {
      if (this.$store.getters.classifiersBO) {
        return this.$store.getters.classifiersBO;
      } return [];
    },
    onbordingImage() {
      return {
        ca: OnboardinformImageCa,
        en: OnboardinformImageEn,
        es: OnboardinformImageEs,
      };
    },
    formImage() {
      if (this.form?.imageURL) {
        return getImageResource(this.form.imageURL);
      } if (this.isOnboarding) {
        return this.onbordingImage[this.currentLocale];
      }
      return formDefaultImage;
    },
    maxScore(){
      return this.form.maxScore ?? 10;
    },
    loggedMember(){
      return this.$store.getters.loggedMember;
    }
  },
  setup() {
    const { getValidationState } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  watch: {
    currentLocale(value) {
      return value;
    },
  },
  async created() {
    console.log('FormPreview created');
    this.isLoading = true;
    this.communityMember = await this.getCommunityMember();
    this.currentSpace = await this.fetchCurrentSpace();
    //console.log('this.currentSpace', this.currentSpace);
    await this.fetchForm();
    if(!this.isFormAlreadyAnswered){
      await this.getCommunityCustomizationsData();
      await this.fetchCountries();
      this.classifiersByType();
    }
    this.isLoading = false;
  },
  methods: {
    redirectHome(){
      return this.$router.replace({
        name: 'Resumen',
        params: { communityId: this.communitySlug },
      });
    },
    closeModal() {
      this.isAddOrganizationModalVisible = false;
      this.membersKey = randomString(10);
    },
    getImageResource(image) {
      return getImageResource(image);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    async fetchForm() {
      let requestConfig = {};
      if (this.isOnboarding) {
        requestConfig = {
          isOnboarding: true,
          communitySlug: this.communitySlug
        };
      } else {
        requestConfig = {
          formKey: this.formKey
        };
      }

      // Fetch form
      await this.$store.dispatch('getItems', {
        itemType: 'forms',
        page: 1,
        requestConfig: requestConfig,
      });

    },
    async fetchCurrentSpace() {
      const currentSpace = await this.$store.dispatch('getItems', {
        itemType: 'communities',
        customName: 'communities',
        perPage: 1,
        forceAPICall: true,
        storedKey: 'allCommunities',
        requestConfig: {
          communitySlug: this.communitySlug,
        },
      });
      //console.log('currentSpace', currentSpace);
      //return currentSpace?.data[0];
      if(currentSpace?.data){
        if(currentSpace.data[0]){
          return currentSpace.data[0];
        }
        return currentSpace.data;
      }
      return null;
    },
    async getCommunityMember() {
      const communityMember = await this.$store.dispatch("getItems", {
        itemType: "communityMembers",
        forceAPICall: true,
        requestConfig: {
          communitySlug: this.communitySlug,
          page: 1,
          perPage: 10,
          username: this.loggedMember.username,
          checkStatus: false,
          getMinimalData: 1
        },
      });
      if(communityMember?.data){
        if(communityMember.data[0]){
          return communityMember.data[0];
        }
        return communityMember.data;
      }
      return null;
    },
    async getCommunityCustomizationsData() {
      await this.$store.dispatch('getItems', {
        itemType: 'CommunityCustomizations',
        customName: 'authImage',
        page: 1,
      });
    },
    async fetchCountries() {
      await this.$store.dispatch('getItems', {
        itemType: 'countries',
        page: 1,
      });
    },
    async fetchOrganizations(page, searchString = '', force) {
      this.isLoadingQuestions = true;
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        isOrganization: true,
        searchString: searchString || '',
      };

      return this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: 'organizations',
        page,
        perPage: 20,
        forceAPICall: force,
        requestConfig,
      });
    },
    async fetchMyOrganizations(page, searchString = '', force) {
      this.isLoadingQuestions = true;
      const requestConfig = {
        // communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        // isOrganization: true,
        mainTypeID: 9,
        searchString: searchString || '',
      };

      return this.$store.dispatch('getItems', {
        itemType: 'community/mySpaces',
        customName: 'communitiesOthers',
        storedKey: 'myOrganizations',
        page,
        perPage: 20,
        forceAPICall: force,
        requestConfig,
      });
    },
    organizationsFilter(response) {
      const organizationOptions = [];
      this.haveOrganizations = true;
      if (response.length > 0) {
        response.forEach((organization) => organization && organizationOptions.push({
          code: organization.key,
          name: organization.name,
        }));
      } else {
        this.haveOrganizations = false;
        organizationOptions.push({
          code: [null],
          name: this.$t('onboarding.form.no-organizations'),
        });
      }
      this.isLoadingQuestions = false;
      return organizationOptions;
    },
    async fetchSocieties(page, searchString = '', force) {
      this.isLoadingQuestions = true;
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        listingType: 0,
        searchString: searchString || '',
      };

      return this.$store.dispatch('getItems', {
        itemType: 'organizations',
        customName: 'communitiesOthers',
        storedKey: 'societies',
        page,
        perPage: 30,
        forceAPICall: force,
        requestConfig,
      });
    },
    societiesFilter(response) {
      const organizationOptions = [];
      if (response.length > 0) {
        this.haveSocieties = true;
        response.forEach((organization) => organization && organizationOptions.push({
          code: organization.key,
          name: organization.name,
        }));
      } else {
        this.haveSocieties = false;
        organizationOptions.push({
          code: [null],
          name: this.$t('onboarding.form.no-societies'),
        });
      }
      this.isLoadingQuestions = false;
      return organizationOptions;
    },
    translate(field) {
      if(!field){
        return null;
      }
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      if (this.code) {
        return translateTranslationTable(this.code, field);
      }
      if (Object.values(this.availableLanguages).find((language) => language === this.currentLocale)) {
        return translateTranslationTable(this.currentLocale, field);
      }
      return translateTranslationTable(this.availableLanguages[0], field);
    },
    async fetchClassifiersByType(question, index) {
      if (question.type === 'classifier') {
        const response = await this.$store.dispatch('getClassifiersBO', {
          key: question.classifierTypeLink.key,
        });
        const classifierOptionArray = [];
        if (response.length > 0) {
          response.forEach((classifier) => {
            const classifierOption = { text: this.translate(classifier.name), code: classifier.key };
            classifierOptionArray.push(classifierOption);
          });
        }
        this.classifiersOptions[this.form.questions[index].key] = classifierOptionArray;
        this.keyToRerender = randomString(10);
      }
    },
    classifiersByType() {
      this.form?.questions?.forEach((question, index) => this.fetchClassifiersByType(question, index));
    },
    selectOptions(options) {
      if (!Array.isArray(options) && Object.values(options).length === 0) {
        return ['No Value'];
      }

      const finalResult = {
        es: [],
        en: [],
        ca: [],
      };

      if (Array.isArray(options)) {
        for (const row of options) {
          for (const key of Object.keys(row)) {
            finalResult[key].push(row[key]);
          }
        }

        options = finalResult;
      }

      if (typeof options === 'string') {
        options = JSON.parse(options);
      }
      if (this.translate(options)?.length > 0 || Object.values(this.translate(options))?.length > 0) {
        return Object.values(this.translate(options));
      }
      if (Object.values(options.es)?.length > 0) {
        return Object.values(options.es);
      }
      if (Object.values(options.ca)?.length > 0) {
        return Object.values(options.ca);
      }
      if (Object.values(options.en)?.length > 0) {
        return Object.values(options.en);
      }
      return [];
    },
    async submitForm() {
      try {
        if (!this.preview) {
          this.isSending = true;
          const answers = this.registerForm;
          //console.log('answers', answers);
          const file = {};
          for(const [key, val] of Object.entries(answers)){
            // console.log('key', key);
            // console.log('val', val);
            const typeofAnsw = typeof val;
            // console.log('typeofAnsw', typeofAnsw);
            if(typeofAnsw === 'object'){
              file[key] = val;
            }
          }
          //console.log('file', file);
          const response = await this.$store.dispatch('createItem', {
            noSet: true,
            file,
            item: {
              itemType: 'formAnswers',
              requestConfig: {
                formKey: this.formKey,
                answers,
              }
            },
          });

          if (this.isOnboarding) {
            if (response.status === 200) {
              this.notifySuccess(this.$t('backoffice.communication.forms.success-message'));
              this.isSending = false;

              // Check if the community has a price
              if (this.$store.getters.currentCollective.hasPrice) {
                // Check if the user is not subscribed
                if (!this.$store.getters.loggedMember.isSubscribed) {
                  // Redirect to plans page
                  return this.$router.replace({
                    name: 'onboarding-plans',
                    params: { communityId: this.communitySlug },
                  });
                }
              }

              const withSuccess = false;
              if(withSuccess) {
                // Redirent to the space home page
                return this.$router.replace({
                    name: 'Resumen',
                    params: { communityId: this.communitySlug },
                  });
              } else {
                // Redirect to onboarding success page
                return this.$router.replace({ 
                  name: 'onboarding-success', 
                  params: { 
                    communityId: this.communitySlug, 
                    subscriptionPlanId: 'thankyou' 
                    } 
                  });
              }
            }
          } else if (response.status === 200) {
            this.notifySuccess(this.$t('backoffice.communication.forms.success-message'));
            this.isSending = false;
            let query = {};
            
            // Check if the form is an exam
            if(this.form.type === 'exam'){
              //console.log('response', response);
              const yourPoints = response.data?.totalScore;
              const totalPoints = this.form.questions.length;
              const yourScore = Number((yourPoints / totalPoints) * this.maxScore).toFixed(2);
              query = {
                yourPoints: yourPoints,
                totalPoints: totalPoints,
                yourScore: yourScore
              };
            }

            //Go to simple form success page
            return this.$router.replace({
              name: "forms-success",
              params: {
                id: this.formKey,
                communityId: this.communitySlug,
                //communityId: this.$route.params.communityId,
              },
              query: query,
            });
          }
          this.isSending = false;
        }
        //this.notifySuccess(this.$t('backoffice.communication.forms.success-message'));
      } catch (error) {
        this.isSending = false;
        this.generalError = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
 .container {
  height: calc(100vh - 4rem);
  width: 100vw;
  min-width: 100vw;
 }
 .image-col {
  background-color: #f8f8f8 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border: 0;
  position: relative;
}
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.scroll-bar::v-deep {
  .__bar-is-vertical {
    background: $gray-300 !important; // Essential important
  }
  .__panel {
    padding: 10px 0;
    margin-right: 0;
  }
  .__view {
    .placeholder {
      height: 75vh;
    }
  }
}
</style>
